/* @import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,800;1,800&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,400;0,800;1,800&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,400;0,800;1,800&family=Playfair+Display:wght@700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'JetBrains Mono', monospace;
    color: var(--light-color);


}

:root {
    --dark-color: #000;
    --light-color: #fff;
    --grey-color: #cccccc;
    --nav-height: 80px;
    --experience-height: 85px;
}

.section {
    display: grid;
    justify-content: center;
    /* align-content: center; */
    width: 100vw;
    max-width: 100%;
    min-height: calc(100vh - var(--nav-height));
    overflow-x: hidden;
}

button {
    background-color: transparent;
    text-decoration: none;
    box-shadow: none;
    border: none;
    font-family: 'JetBrains Mono', monospace;
    color: #fff;
    text-decoration: none;
    font-style: italic;
    font-size: larger;
    cursor: pointer;
}

.active {
    text-decoration: underline;
}

a {
    color: #fff;
    text-decoration: none;
    font-style: italic;
    font-size: larger;
    cursor: pointer;
}


/* WebKit and Opera browsers */
@-webkit-keyframes spinner {
    from {
        -webkit-transform: translate3d(0%, 0px, 0px);
    }

    to {
        -webkit-transform: translate3d(-100%, 0px, 0px);
    }
}

/* all other browsers */
@keyframes spinner {
    from {
        -moz-transform: translate3d(0%, 0px, 0px);
        -ms-transform: translate3d(0%, 0px, 0px);
        transform: translate3d(0%, 0px, 0px);
    }

    to {
        -moz-transform: translate3d(-100%, 0px, 0px);
        -ms-transform: translate3d(-100%, 0px, 0px);
        transform: translate3d(-100%, 0px, 0px);
    }
}

#spinner {
    -webkit-animation-name: spinner;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 15s;

    animation-name: spinner;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-duration: 15s;

    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.rotate-box {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    top: 0;
    /* grid-column: 1 / span 4; */
    background-color: #000;
    z-index: 0;
    height: 60px;
}

.header-solid {
    color: #fff;
}

.header-hollow {
    -webkit-text-fill-color: rgba(255, 255, 255, 0);
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: var(--light-color);
}


/* Navigation */
.nav {
    width: 100vw;
    background: var(--dark-color);
    height: 80px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    bottom: 0%;
    position: fixed;
    z-index: 1000;
}

.nav button:hover {
    transform: scale(1.1);
}

.logos {
    background-color: transparent;
    display: grid;
    grid-template-rows: repeat(4, 1fr);
    right: 0;
    bottom: 25px;
    position: fixed;
    justify-content: space-around;
    align-items: center;
    padding: 25px;
    height: 100%;
    z-index: 100;
}

.logo path {
    fill: #777;
}

.logo:hover,
.logo:hover path {
    transform: scale(1.1);
    fill: var(--light-color);
}

.background {
    background-attachment: fixed;
    background-position: center;
    max-width: 100%;
    height: 100vh;
    width: 100vw;
    position: absolute;
    z-index: -100;
}

.brightness {
    filter: brightness(0.4);
}

.grayscale {
    filter: grayscale(100%) brightness(0.4);
}

/* Landing page */
.landing {
    align-content: center;
}

.landing h1 {
    font-style: italic;
    font-size: 110px;
    text-align: center;
}

.landing h3 {
    font-style: italic;
    font-size: 35px;
    text-align: center;
    color: var(--grey-color);
}

/* About Page */
.about {
    /* grid-template-columns: repeat(4, 1fr); */
    /* grid-template-rows: 1fr 2fr 6fr; */
    column-gap: 4rem;
    /* row-gap: 1rem; */
    background-color: var(--dark-color);
    /* min-height: 100vh; */
    grid-template-rows: 60px auto;
    /* justify-content: center; */
    display: grid;
}

.about-info {
    display: grid;
    max-width: 1100px;
    justify-self: center;
    column-gap: 1rem;
    padding: 1rem;
    place-self: center;
}

.about .header {
    display: flex;
    height: 60px;
    font-size: 40px;
    place-items: center stretch;
    background-color: #000;
    justify-self: center;
    align-self: center;
    white-space: nowrap;
    font-style: italic;
    overflow-x: visible;

}


.about .svg-1 {
    justify-self: end;
    align-self: end;
}

.about path {
    fill: #823b27;
}

.about .p-1 {
    grid-column: 2 / span 1;
    color: #b2b2b2;
    max-width: 350px;
    padding-bottom: 10px;
}

.about .p-2 {
    grid-column: 3 / span 1;
    color: #b2b2b2;
    max-width: 350px;
}

.about h2 {
    grid-column: 2 / span 1;
    padding-bottom: 5px;
    color: #823b27;
    font-weight: normal;
    font-size: larger;
    justify-self: start;
    align-self: end;
}

.skills {
    display: grid;
}

.about .skills .skills-list {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.about .skills h2 {
    justify-self: center;
    grid-column: 1 / span 1;
    align-self: end;
    text-align: center;

}

.skills-list li::marker {
    content: none;
}

/* Experience */
.experience {
    background-color: var(--dark-color);
    min-height: calc(100vh - var(--nav-height));
    height: 100%;
    display: grid;
    align-items: center;
    /* justify-content:space-around; */

}

.experience .header {
    /* grid-column: 1 / span 3; */
    align-self: center;
    justify-self: center;
    text-align: center;
    font-family: 'Playfair Display', serif;
    font-size: 100px;
    display: inline;
    z-index: 0;
    width: 100%;
    padding-bottom: 4vh;
}

.circle {
    height: 120px;
    width: 120px;
    background-color: #823b27;
    border-radius: 50%;
    z-index: -1;
    position: absolute;

}

.experience .circle {
    left: 42%;
}

.contact .circle {
    left: 23%;
    width: 140px;
    height: 140px;

}

.experience h2 {
    font-family: 'JetBrains Mono', monospace;
    font-size: 20px;
    font-weight: normal;
    text-decoration: underline;
}

/* Job */
.jobs {
    grid-column: 2 / span 1;
    max-width: 900px;
    justify-self: center;
    /* display: grid; */
}

.job {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr 3fr;
    background-color: #000;
    margin: 20px 0;
    border-radius: 15px;
    color: #fff;
    border: 1px solid #fff;
}

.job:hover {
    border: 1px solid #000;
}

.job:hover,
.job:hover p,
.job:hover h3 {
    background-color: #fff;
    color: #000;
}

.job:hover p {
    border-top: 1px solid #000;
    ;
}

.job h3 {
    color: #fff;
    justify-self: center;
    align-self: center;
    padding: 5px;
    font-weight: normal;
}

.job p {
    color: #fff;
    grid-column: 1 / span 2;
    padding: 10px;
    margin: 5px;
    justify-self: center;
    border-top: 1px solid #fff;
    ;
}

/* Projects */

.projects {
    background-color: black;
    color: #fff;
}

.projects-list {
    display: grid;
    grid-column: 1 / span 4;
    justify-content: center;
}

.projects .rotate-box {
    grid-column: 1 / span 4;
}

.projects .header {
    display: flex;
    height: 60px;
    font-size: 40px;
    place-items: center stretch;
    background-color: #000;
    justify-self: center;
    align-self: center;
    white-space: nowrap;
    font-style: italic;
}

/* .projects path {
    fill: #aaa;
} */

/* Project */


.project {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 1fr;
    /* margin-bottom: 60px;
    margin-top: 60px; */
    margin: 60px 40px;
    margin-right: 70px;
    /* justify-self: center; */
    max-width: 1100px;

}

.project img {
    max-height: 350px;
    filter: grayscale(90%);
    z-index: 0;
    border-radius: 2px;
}

.project:hover .static {
    opacity: 0;
}

.project:hover img {
    filter: none;
    transition: filter 1s;
}

.project-left img {
    grid-area: 1 / 1 / -1 / 7;
    grid-column: 1 / span 5;
}

.project-right img {
    grid-area: 1 / 1 / -1 / 7;
    grid-column: 6 / span 5;
}

.info {
    grid-area: 1 / 1 / -1 / 7;
    align-self: center;
    z-index: 10;
    filter: drop-shadow(10px 10px 30px rgb(47, 47, 47))
}

.project-left .info {
    grid-column: 5 / span 7;
    align-items: end;
}

.project-right .info {
    grid-column: 1 / span 7;
    align-items: start;
}

.project-left .info h5 {
    text-align: end;

}

.info h2 {
    color: #823b27;
    /* font-weight: normal; */
}

.project-left .info h2 {
    text-align: end;
}

.info h4 {
    margin-top: 15px;
    margin-bottom: 15px;
    font-weight: normal;
    font-size: 15px;
    color: #aaa;
}

.project-left .info h4 {
    text-align: end;
}

.info p {
    background-color: rgba(0, 0, 0, 0.8);
    padding: 15px;
    margin-top: 40px;
    /* border-radius: 7px; */
}

.project-left .info p {
    text-align: end;
}

.info a {
    text-decoration: none;
    color: #aaa;
    padding: 10px 10px;
    position: relative;
    font-size: 20px;
}

.project-left .info a {
    text-align: end;
}

.info a:hover {
    text-decoration: none;
    color: #fff;
    transition: color 500ms;
}

.links {
    display: flex;

}

.project-left .links {
    align-items: end;
    justify-content: end;
}

/* .project .p-1 {
    grid-column: 1 / span 1 ;
    grid-row: 3 / span 1 ;
    color: #b2b2b2;
}

.project .p-3 {
    grid-column: 1 / span 1 ;
    grid-row: 2 / span 1 ;
    color: #b2b2b2;
}

.project .p-2 {
    grid-column: 3 / span 1;
    color: #b2b2b2;
    align-self: start;
    font-style: normal;
}

.p-2 li {
    color: #b2b2b2;
}

.projects .project h2 {
    padding-bottom: 5px;
    color: #823b27;
    font-weight: normal;
    font-size: larger;
    justify-self: start;
    align-self: end;
    font-style: normal;
} */

/* Project */
/* .project {
    align-self: start;
    grid-column: 2 / span 1;
    grid-template-columns: 5fr 1fr 5fr;
    display: grid;
    padding: 15px;
}

.project:hover {
    background-color: #222;
    border-radius: 15px;
    cursor: pointer;
    filter: none;
    
}
.project:hover img {
    filter: none;
}

.project img {
    grid-row: 1 / span 2;
    object-fit: contain;
    filter: grayscale(100%);
}

.project .svg-1 {
    align-self: end;
    justify-self: end;
    font-size: 27px;
    padding-bottom: 10px;
} */

/* Contact */
.contact {
    height: calc(100vh - var(--nav-height));
    display: grid;
    grid-template-columns: 1fr 5fr 5fr 1fr
}

.contact .header {
    grid-column: 2/ span 1;
    align-self: center;
    justify-self: center;
    font-family: 'Playfair Display', serif;
    font-size: 100px;
    display: inline;
}

.contact .form {
    display: grid;
    grid-template-rows: 1fr 1fr 5fr;
    align-items: center;
    align-self: center;
    justify-content: stretch;
    padding: 20px;
    min-height: 50vh;
    max-height: 450px;
    max-width: 400px;
}

::placeholder {
    color: var(--dark-color);
}

.form input {
    padding: 10px;
    margin: 10px 0;
    font-size: larger;
    color: var(--dark-color);
}

.form button {
    background-color: #823b27;
    padding: 10px 40px;
    justify-self: center;
    border-radius: 10px;
}

textarea {
    align-self: start;
    padding: 10px;
    margin: 10px 0;
    width: 100%;
    height: 90%;
    resize: none;
    font-size: larger;
    color: var(--dark-color);
}

/* Experience Update */

@media (max-width: 405px) {
    .nav button {
        font-size: 17px !important;
    }
}

@media (max-width: 550px) {

    .landing h1 {
        font-size: 90px;
    }

    .landing h3 {
        font-size: 25px
    }

    .experience .header {
        font-size: 65px !important;
    }

    .job-table {
        padding: 0px 5px !important;

    }

    .circle {
        height: 100px;
        width: 100px;
    }

    .company h3 {
        height: var(--experience-height);
        font-size: 14px;
    }

    .role h1,
    .role h2 {
        font-size: 12px !important;
        padding-bottom: 5px;
    }

    .role p {
        font-size: 13px !important;
    }

    .about .header {
        display: none;
    }


}

@media (max-width: 800px) {
    .logos {
        position: absolute;
        width: 100%;
        grid-template-columns: repeat(4, 1fr) !important;
        display: grid;
        justify-items: center;

    }

    .about .p-2 {
        grid-column: 2 / span 1;
        padding-top: 10px;
    }



    .companies {
        height: auto;
        flex-direction: row;
        grid-column: 1 /span 2;
        border-top: none;
    }

    .activeBar {
        visibility: hidden;
    }

    .job-table {
        display: grid;
        grid-template-columns: 1fr;
    }

    .roles {
        grid-column: 1 / span 2;
    }

}

@media (max-width: 1030px) {

    .project {
        margin: 50px 3px !important;
    }

    .project img {
        grid-column: 1 / span 12 !important;
        justify-self: center;
        align-self: center;
        width: auto;
        max-width: 100%;
        /* filter: grayscale(100%) !important; */
    }

    .project:hover .static {
        opacity: 1;
    }

    .project-left img {
        grid-area: 1 / 1 / -1 / 13 !important;
        grid-column: 1 / span 12;
    }

    .project-right img {
        grid-area: 1 / 1 / -1 / 13 !important;
        grid-column: 1 / span 12;
    }

    .info {
        grid-area: 1 / 1 / -1 / 12 !important;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        filter: none !important;
        text-align: center;
        /* grid-column: 1 / span 13; */
    }

    .info h2 {
        font-weight: normal;

    }

    .info p {
        background-color: transparent !important;
        font-size: 16px;
        text-align: center;
        margin-top: 10px;
    }

    .project-left .info,
    .project-right .info {
        grid-column: 1 / span 12 !important;
        align-items: start;
        width: 100%;
    }

    .project-left .info p {
        grid-column: 1 / span 12 !important;
        width: 100%;
        text-align: center !important;
    }

    .project-left .info h2,
    .project-left .info h5,
    .project-left .info h4 {
        text-align: center !important;
    }

    .project-left .links,
    .project-right .links {
        justify-content: center !important;
    }

    .contact {
        grid-template-columns: auto;
    }

    .contact .header {
        font-size: 90px;
    }


    .contact .header,
    .contact .form {
        grid-column: 1 / span 2;
        justify-self: center;
        width: 100%;
    }

    .contact .circle {
        left: 37%;
        width: 140px;
        height: 140px;
    }


}



@media (min-width: 801px) {
    .companies {
        height: calc(4 * var(--experience-height));
        flex-direction: column;
        border-left: 4px solid #777;
    }

    .activeBar {
        height: var(--experience-height);
        position: absolute;
        border-left: 2px solid #fff;
        border-right: 2px solid #fff;
        transition: transform 1s;
    }
}

.job-table {
    display: grid;
    justify-self: center;
    align-self: start;
    grid-template-columns: 1fr 4fr;
    background-color: #000;
    max-width: 900px;
    padding: 0 25px;
}

.companies {
    display: flex;

}

.company {
    height: var(--experience-height);
    align-self: center;
}

.company h3 {
    display: grid;
    cursor: pointer;
    height: var(--experience-height);
    width: 100%;
    justify-content: center;
    text-align: center;
    align-content: center;
}

.company .active {
    background-color: #222;
    transition: background-color 1s;
    text-decoration: none;
    width: 100%;
}

.role p {
    grid-column: 2 / span 1;
    padding: 10px 5px;
    font-size: 14px;
}

/* .activeBar {
    height: var(--experience-height);
    position: absolute;
    border-left: 2px solid #fff;
    border-right: 2px solid #fff;
    transition: transform 1s;
} */

.church {
    transform: translateY(200%);
}

.dh {
    transform: translateY(300%);

}

.nissan {
    transform: translateY(100%);
}

.role h1 {
    text-align: start;
    font-size: 19px;
    font-family: 'JetBrains Mono', monospace;
    font-weight: normal;
    padding-bottom: 5px;
}

.role {
    display: grid;
    padding-top: 10px;
    font-family: 'JetBrains Mono', monospace;
    padding-left: 10px;
}

.role h2 {
    font-size: 15px;
    align-self: center;
    justify-self: end;
    color: #777;
    font-weight: normal;
    text-decoration: none;
}

.role p {
    grid-column: 1 / span 2;
    padding-left: 20px;
    border-top: 1px solid #fff;
}

li {
    padding: 5px 0;
}

ul li::marker {
    padding-left: 5px;
    content: " ▹ ";
    font-size: 15px;
}